import React, { useCallback, useEffect } from "react";
import { shallow } from "zustand/shallow";
import { RBACProvider } from "react-simple-rbac";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { CacheProvider } from "@emotion/react";
import { useAuth0 } from "@auth0/auth0-react";
import { decodeJwt } from "jose";

// import Login from "./pages/login";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";

// import Login from "./pages/login";
//Pages
import AddCompanyPage from "./pages/crearempresa";
import AddIdentificationPage from "./pages/addIdentification";
import AddNoveltyPage from "./pages/addnovelty";
import AddPeriodPage from "./pages/addperiod";
import AddUserPage from "./pages/adduser";
import CallbackPage from "./pages/callback";
import CrearNovedadPage from "./pages/crearnovedad";
import CrearTipoDeNovedadPage from "./pages/asignartipodenovedad";
import CrearUsuarioPage from "./pages/crearusuario";
import EmpresaPage from "./pages/empresa";
import EmpresasPage from "./pages/empresas";
import EmpresasParametrosPage from "./pages/empresasparamteros";
import EmpresaSelectParametrosPage from "./pages/empresa-select-parametros";
import HistorialPage from "./pages/historial";
import NewNewLogin from "./pages/newnewlogin";
import NominaPage from "./pages/nomina";
import NovedadesPage from "./pages/novedades";
import ParametrosPage from "./pages/parametros";
import PerfilPage from "./pages/perfil";
import PeriodosPage from "./pages/periodos";
import RedirectLogin from "./pages/redirect-login";
import SelectCompanyPage from "./pages/selectcompany";
import UsersPage from "./pages/users";

//Details pages
import EmpresaNovedadDetailsPage from "./pages/details/empresa-novedad-details";
import EmpresaTipoNovedadDetailsPage from "./pages/details/empresa-tipo-novedad-details";
import EmpresaTipoPeriodoDetailsPage from "./pages/details/empresa-tipo-periodo-details";
import HistorialDetailsPage from "./pages/details/historial-details";
import NominaUsusarioDetailsPage from "./pages/details/nomina-usuario-details";
import NovedadDetailsPage from "./pages/details/novedad-details";
import ParametroIdentificacionDetailsPage from "./pages/details/parametro-identificacion-details";
import ParametroNovedadDetailsPage from "./pages/details/parametro-novedad-details";
import ParametroPeriodosDetailsPage from "./pages/details/parametro-periodo-details";
import PeriodoDetailsPage from "./pages/details/periodo-details";
import UsusarioDetailsPage from "./pages/details/usuarios-usuario-details";

import CompaniesPeriodosDetailsPage from "./pages/details/ajustedeportal/companies-parametro-periodo-details";
import CompaniesParametroNovedadDetailsPage from "./pages/details/ajustedeportal/companies-parametro-novedad-details";
import CompaniesParametroIdentificacionDetailsPage from "./pages/details/ajustedeportal/companies-parametro-identificacion-details";

//Components
import InactiveUser from "./components/InactiveUser/InactiveUser";
import LoginErrorMessage from "./components/LoginErrorMessage/LoginErrorMessage";
import ProtectedRoute from "./components/ProtectedRoute/ProtectedRoute";
// import NewLogin from "./pages/newlogin";

//Contexts
import { EmpresaProvider } from "./context/empresa/EmpresaProvider";
import { ParametroProvider } from "./context/parametro/ParametroProvider";

import { createEmotionCache } from "./utils/create-emotion-cache";

import { useDrawerStore } from "./store/drawerStore";
import { usePermissionsStore } from "./store/permissionsStore";
import { useUserStore } from "./store/userStore";

import { linksMenuPortal } from "./utils/pathsArray";

import { theme } from "./theme";

import "dayjs/locale/es";

import "./App.css";

const defaultRoles = ["admin", "basic", "manager", "read_only"];

const emotionCache = createEmotionCache();

function App() {
  const { getAccessTokenSilently, isLoading, error } = useAuth0();

  const setHasFetchUserDataError = useUserStore(
    (state) => state.setHasFetchUserDataError
  );

  const { hasFetchUserDataError, errorMessage, user } = useUserStore(
    ({ hasFetchUserDataError, errorMessage, user }) => ({
      hasFetchUserDataError,
      errorMessage,
      user,
    }),
    shallow
  );

  const { permissions, roles } = usePermissionsStore(
    ({ permissions, roles }) => ({ permissions, roles }),
    shallow
  );

  const { setPermissions, setRoles } = usePermissionsStore(
    ({ setPermissions, setRoles }) => ({ setPermissions, setRoles })
  );

  const { setChangeLink, setTopLink } = useDrawerStore(
    ({ setChangeLink, setTopLink }) => ({
      setChangeLink,
      setTopLink,
    })
  );

  const getUserPermissions = useCallback(async () => {
    const token = await getAccessTokenSilently();
    const claims = decodeJwt(token);
    if (claims.permissions) {
      setPermissions(claims.permissions);
    }
    setRoles(defaultRoles);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const handleBeforeUnload = () => {
      setHasFetchUserDataError(false); // Setea el error al obtener datos del usuario a false
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [setHasFetchUserDataError]);

  useEffect(() => {
    if (user) {
      getUserPermissions();
      if (window.location.pathname === "/") {
        if (user.status === 0) {
          window.history.replaceState({}, "", "/");
        } else if (user.type === 1) {
          setChangeLink(1);
          setTopLink(user.userTopLink);
          window.history.replaceState({}, "", "/empresas");
        } else {
          setChangeLink(2);
          setTopLink(user.userTopLink);
          window.history.replaceState({}, "", "/novedades");
        }
      }
    }
  }, [getUserPermissions, setChangeLink, setTopLink, user]);

  useEffect(() => {
    const handlePopState = () => {
      // Acciones cuando el usuario hace clic en "atrás"

      // Expresión regular para buscar un UUID al final de la URL
      const finalIdRegex = /[a-fA-F0-9]{24}$/;
      // Expresión regular para buscar un UUID en el medio de la URL
      const middleIdRegex = /\/[a-fA-F0-9]{24}\//;
      // Pathname de la URL
      const urlPathname = window.location.pathname;

      if (middleIdRegex.test(urlPathname)) {
        const urlWithoutMiddleIdPathname = urlPathname.replace(
          middleIdRegex,
          "/"
        );

        if (finalIdRegex.test(urlWithoutMiddleIdPathname)) {
          if (
            linksMenuPortal.includes(
              urlWithoutMiddleIdPathname.slice(0, -24)
            ) &&
            user?.type === 1
          ) {
            setChangeLink(1);
          } else {
            setChangeLink(2);
            /* // setTopLink(user?.userTopLink || "/gestiondeclientes");
          window.history.replaceState({}, "", "/novedades"); */
          }
        } else {
          if (
            linksMenuPortal.includes(urlWithoutMiddleIdPathname) &&
            user?.type === 1
          ) {
            setChangeLink(1);
          } else {
            setChangeLink(2);
            /* // setTopLink(user?.userTopLink || "/gestiondeclientes");
        window.history.replaceState({}, "", "/novedades"); */
          }
        }
      } else if (finalIdRegex.test(urlPathname)) {
        if (
          linksMenuPortal.includes(urlPathname.slice(0, -24)) &&
          user?.type === 1
        ) {
          setChangeLink(1);
        } else {
          setChangeLink(2);
          /* // setTopLink(user?.userTopLink || "/gestiondeclientes");
        window.history.replaceState({}, "", "/novedades"); */
        }
      } else {
        if (linksMenuPortal.includes(urlPathname) && user?.type === 1) {
          setChangeLink(1);
        } else {
          setChangeLink(2);
          /* // setTopLink(user?.userTopLink || "/gestiondeclientes");
        window.history.replaceState({}, "", "/novedades"); */
        }
      }
    };

    window.addEventListener("popstate", handlePopState);

    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, [setChangeLink, user?.type, user?.userTopLink]);

  useEffect(() => {
    document.title = `${window._env_.REACT_APP_EMPRESA_NAME}`;
  }, []);

  if (isLoading) {
    return (
      <div className="page-layout">
        <CallbackPage />
      </div>
    );
  }

  if (error) {
    return (
      <div className="page-layout">
        <LoginErrorMessage
          cause={error.cause || "auth0 login error"}
          name={error.name || "name"}
          message={error.message || "message"}
        />
      </div>
    );
  } else if (hasFetchUserDataError) {
    return (
      <div className="page-layout">
        <LoginErrorMessage cause={errorMessage} name="" message="" />
      </div>
    );
  }

  return (
    <div className="App">
      <RBACProvider roles={roles} permissions={permissions}>
        <ParametroProvider>
          <CacheProvider value={emotionCache}>
            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              adapterLocale={"es"}
            >
              <ThemeProvider theme={theme}>
                <CssBaseline />
                <CacheProvider value={emotionCache}>
                  <Router>
                    <EmpresaProvider>
                      <Routes>
                        <Route
                          path="/"
                          element={
                            user ? (
                              user?.status === 0 ? (
                                <InactiveUser />
                              ) : user?.type === 1 ? (
                                <ProtectedRoute
                                  requiredPermissions={["read:companies"]}
                                  noEmpresa
                                  component={EmpresasPage}
                                />
                              ) : (
                                <SelectCompanyPage />
                              )
                            ) : (
                              <NewNewLogin />
                            )
                          }
                        />
                        <Route path="/login" element={<RedirectLogin />} />
                        <Route
                          path="/gestiondecliente"
                          element={
                            <ProtectedRoute component={SelectCompanyPage} />
                          }
                        />
                        <Route
                          path="/novedades"
                          element={
                            <ProtectedRoute
                              roleNeeded={1}
                              redirectTo="/historial"
                              component={NovedadesPage}
                            />
                          }
                        />
                        <Route
                          path="/novedades/novedad/:novedadId"
                          element={
                            <ProtectedRoute
                              roleNeeded={1}
                              redirectTo="/novedades"
                              component={NovedadDetailsPage}
                            />
                          }
                        />
                        <Route
                          path="/novedades/crearnovedad"
                          element={
                            <ProtectedRoute
                              roleNeeded={1}
                              redirectTo="/novedades"
                              requiredPermissions={["create:novelty"]}
                              component={CrearNovedadPage}
                            />
                          }
                        />
                        <Route
                          path="/parametrosportal/creartipodeperiodo"
                          element={
                            <ProtectedRoute
                              requiredPermissions={["create:period-type"]}
                              noEmpresa
                              component={AddPeriodPage}
                            />
                          }
                        />
                        <Route
                          path="/parametrosportal/creartipodenovedad"
                          element={
                            <ProtectedRoute
                              requiredPermissions={["create:novelty-type"]}
                              noEmpresa
                              component={AddNoveltyPage}
                            />
                          }
                        />
                        <Route
                          path="/parametrosportal/creartipodeidentificacion"
                          element={
                            <ProtectedRoute
                              requiredPermissions={["create:identification-type"]}
                              noEmpresa
                              component={AddIdentificationPage}
                            />
                          }
                        />
                        <Route
                          path="/parametros/tipodeperiodo/periodo/:periodoId"
                          element={
                            <ProtectedRoute
                              component={ParametroPeriodosDetailsPage}
                            />
                          }
                        />
                        <Route
                          path="/parametrosportal/tipodeperiodo/periodo/:periodoId"
                          element={
                            <ProtectedRoute
                              component={CompaniesPeriodosDetailsPage}
                              noEmpresa
                            />
                          }
                        />
                        <Route
                          path="/parametros/tipodenovedad/novedad/:novedadId"
                          element={
                            <ProtectedRoute
                              component={ParametroNovedadDetailsPage}
                            />
                          }
                        />
                        <Route
                          path="/parametrosportal/tipodenovedad/novedad/:novedadId"
                          element={
                            <ProtectedRoute
                              component={CompaniesParametroNovedadDetailsPage}
                              noEmpresa
                            />
                          }
                        />
                        <Route
                          path="/parametros/identificaciones/identificacion/:identificacionId"
                          element={
                            <ProtectedRoute
                              component={ParametroIdentificacionDetailsPage}
                            />
                          }
                        />
                        <Route
                          path="/parametrosportal/identificaciones/identificacion/:identificacionId"
                          element={
                            <ProtectedRoute
                              component={
                                CompaniesParametroIdentificacionDetailsPage
                              }
                              noEmpresa
                            />
                          }
                        />
                        <Route
                          path="/empresas/empresa/:empresaId/parametros/tipodenovedad/:novedadId"
                          element={
                            <ProtectedRoute
                              component={EmpresaTipoNovedadDetailsPage}
                              noEmpresa
                            />
                          }
                        />
                        <Route
                          path="/empresas/empresa/:empresaId/parametros/creartipodenovedad"
                          element={
                            <ProtectedRoute
                              component={CrearTipoDeNovedadPage}
                              noEmpresa
                            />
                          }
                        />
                        <Route
                          path="/empresas/empresa/:empresaId/parametros/tipodeperiodo/periodo/:periodoId"
                          element={
                            <ProtectedRoute
                              component={EmpresaTipoPeriodoDetailsPage}
                              noEmpresa
                            />
                          }
                        />
                        <Route
                          path="/usuarios"
                          element={
                            <ProtectedRoute
                              component={UsersPage}
                              requiredPermissions={["read:users"]}
                              noEmpresa
                            />
                          }
                        />
                        <Route
                          path="/usuarios/crearusuario"
                          element={
                            <ProtectedRoute
                              component={CrearUsuarioPage}
                              noEmpresa
                            />
                          }
                        />
                        <Route
                          path="/usuarios/usuario/:usuarioId"
                          element={
                            <ProtectedRoute
                              component={UsusarioDetailsPage}
                              noEmpresa
                            />
                          }
                        />
                        <Route
                          path="/perfil"
                          element={
                            <ProtectedRoute component={PerfilPage} noEmpresa />
                          }
                        />
                        <Route
                          path="/empresa"
                          element={<ProtectedRoute component={EmpresaPage} />}
                        />
                        <Route
                          path="/empresas"
                          element={
                            <ProtectedRoute
                              requiredPermissions={["read:companies"]}
                              component={EmpresasPage}
                              noEmpresa
                            />
                          }
                        />
                        <Route
                          path="/empresas/empresa/:empresaId"

                          element={
                            <ProtectedRoute
                              requiredPermissions={["read:companies", "read:company"]}
                              component={EmpresaNovedadDetailsPage}
                              noEmpresa
                            />
                          }
                        />
                        <Route
                          path="/empresas/empresa/:empresaId/parametros"
                          element={
                            <ProtectedRoute
                              requiredPermissions={["read:companies", "read:company", "create:period-type", "create:novelty-type"]}
                              component={EmpresaSelectParametrosPage}
                              noEmpresa
                            />
                          }
                        />
                        <Route
                          path="/empresas/empresa/crear empresa"
                          element={
                            <ProtectedRoute
                            requiredPermissions={["read:companies", "read:company", "create:companies"]}
                            component={AddCompanyPage}
                              noEmpresa
                            />
                          }
                        />
                        <Route
                          path="/empresas/empresa/crear usuario"
                          element={
                            <ProtectedRoute component={AddUserPage} noEmpresa />
                          }
                        />
                        <Route
                          path="/ajustes"
                          element={<ProtectedRoute component={PerfilPage} />}
                        />
                        <Route path="/callback" element={<CallbackPage />} />
                        <Route
                          path="*"
                          element={
                            <ProtectedRoute
                              roleNeeded={1}
                              redirectTo="/novedades"
                              component={NovedadesPage}
                            />
                          }
                        />
                        <Route
                          path="/historial"
                          element={
                            <ProtectedRoute
                              roleNeeded={1}
                              redirectTo="/novedades"
                              component={HistorialPage}
                            />
                          }
                        />
                        <Route
                          path="/historial/novedad/:novedadId"
                          element={
                            <ProtectedRoute
                              roleNeeded={1}
                              redirectTo="/novedades"
                              component={HistorialDetailsPage}
                            />
                          }
                        />
                        <Route
                          path="/nomina"
                          element={<ProtectedRoute component={NominaPage} />}
                        />
                        <Route
                          path="/nomina/usuario/:usuarioId"
                          element={
                            <ProtectedRoute
                              component={NominaUsusarioDetailsPage}
                            />
                          }
                        />
                        <Route
                          path="/periodos"
                          element={<ProtectedRoute component={PeriodosPage} />}
                        />
                        <Route
                          path="/periodos/periodo/:periodoId"
                          element={
                            <ProtectedRoute component={PeriodoDetailsPage} />
                          }
                        />
                        <Route
                          path="/parametros"
                          element={
                            <ProtectedRoute component={ParametrosPage} />
                          }
                        />
                        <Route
                          path="/parametrosportal"
                          element={
                            <ProtectedRoute
                              requiredPermissions={[
                                "create:identification-type",
                                "create:novelty-type-company",
                                "create:novelty-type",
                                "create:period-type-company",
                                "create:period-type",
                                "delete:novelty-type-company",
                                "delete:novelty-type",
                                "delete:period-type-company",
                                "delete:period-type",
                                "read:identification-type",
                                "read:identification-types",
                                "read:novelty-type",
                                "read:novelty-types",
                                "read:period-type",
                                "read:period-types",
                                "update:identification-type",
                                "update:novelty-type-company",
                                "update:novelty-type",
                                "update:period-type",
                              ]}
                              component={EmpresasParametrosPage}
                              noEmpresa
                            />
                          }
                        />
                      </Routes>
                    </EmpresaProvider>
                  </Router>
                </CacheProvider>
              </ThemeProvider>
            </LocalizationProvider>
          </CacheProvider>
        </ParametroProvider>
      </RBACProvider>
    </div>
  );
}

export default App;
