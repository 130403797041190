import React, { useCallback, useContext, useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import { shallow } from "zustand/shallow";

import Box from "@mui/material/Box";
import {
  GridCellParams,
  GridColDef,
  GridRenderCellParams,
  GridValueFormatterParams,
  MuiEvent,
} from "@mui/x-data-grid";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";

import SearchBar from "material-ui-search-bar";

import { MaterialIcons } from "../../icons/Material/MaterialIcons";
import DataTable from "../table/DataTable";

import {
  getPeriodType,
  getCompanyPeriodsTypes,
} from "../../services/CompaniesService";

import { DrawerContext } from "../../context/drawer/DrawerContext";

import { useEmpresaStore } from "../../store/empresaStore";
import { useParametrosStore } from "../../store/parametrosStore";

import { TipoPeriodoRowsType } from "../../types";
import HtmlTooltip from "../HtmlTooltip/HtmlTooltip";
import { useHasPermissions } from "react-simple-rbac";

const durationArray = [
  {
    id: 0,
    value: "Mensual",
  },
  {
    id: 1,
    value: "Quincenal",
  },
];

//1: success, 2:pending, 3:cancel
const getAction = (
  params: GridRenderCellParams,
  icon: keyof typeof MaterialIcons
) => {
  const Icon = MaterialIcons[icon || "PollIcon"];
  if (params.value === "2") {
    return (
      <Box
        sx={{
          display: "flex",
          placeItems: "center",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Button variant="contained" color="primary">
          Procesar ahora
        </Button>
        <Icon />
      </Box>
    );
  }

  return (
    <Box
      sx={{
        display: "flex",
        placeItems: "center",
        justifyContent: "flex-end",
        width: "100%",
      }}
    >
      <Icon />
    </Box>
  );
};

const getDuration = (params: GridValueFormatterParams) => {
  return durationArray.find((duration) => duration.id === params.value)?.value;
};

const TiposPeriodos = () => {
  const [searched, setSearched] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [fetchError, setFetchError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [periods, setPeriods] = useState<TipoPeriodoRowsType[]>([]);
  const [orignialPeriods, setOriginalPeriods] = useState<TipoPeriodoRowsType[]>(
    []
  );

  const navigate = useNavigate();

  const { getAccessTokenSilently } = useAuth0();

  const setAllPeriodos = useParametrosStore((state) => state.setAllPeriodos);

  const { changeLink } = useContext(DrawerContext);

  const empresaId = useEmpresaStore((state) => state.empresaId, shallow);

  const canCreatePeriodType = useHasPermissions(["create:period-type"]);


  const columns: GridColDef[] = [
    // {
    //   field: "id",
    //   headerName: "ID",
    //   flex: 0.4,
    //   headerClassName: "theme--header",
    //   hideable: true,
    // },
    {
      field: "name",
      headerName: "Nombre",
      flex: 1,
      editable: true,
      headerClassName: "theme--header",
    },
    {
      field: "duration",
      headerName: "Duración",
      flex: 1,
      editable: true,
      headerClassName: "theme--header",
      valueFormatter: getDuration,
    },
    {
      field: "day_limit",
      headerName: "Día limite novedades",
      headerClassName: "theme--header",
      flex: 1,
    },
    {
      field: "periods_before",
      headerName: "Anticipo",
      headerClassName: "theme--header",
      flex: 1,
    },
    {
      field: "action",
      headerName: "Acción",
      type: "number",
      flex: 1,
      editable: false,
      headerClassName: "theme--header",
      renderCell(params) {
        return getAction(
          params,
          changeLink === 1 ? "EditIcon" : "VisibilityOutlined"
        );
      },
    },
  ];

  const requestSearch = (searchedVal: string) => {
    const searchPeriodType = [...orignialPeriods];
    if (searchedVal.length > 2) {
      const filteredRows = searchPeriodType.filter((row) => {
        return row.name.toLowerCase().includes(searchedVal.toLowerCase());
      });
      setPeriods(filteredRows);
    } else {
      setPeriods(orignialPeriods);
    }
  };

  const addPeriod = () => {
    navigate("/parametrosportal/creartipodeperiodo");
  };

  const cancelSearch = () => {
    setSearched("");
    requestSearch(searched);
  };

  const handleOnCellClick = (
    params: GridCellParams,
    event: MuiEvent<React.MouseEvent>
  ) => {
    if (changeLink === 1) {
      navigate(`/parametrosportal/tipodeperiodo/periodo/${params.id}`);
    } else {
      navigate(`/parametros/tipodeperiodo/periodo/${params.row.id}`);
    }
  };

  const getPeriod = useCallback(async () => {
    setIsLoading(true);
    const token = await getAccessTokenSilently();

    changeLink === 1
      ? getPeriodType(token)
        .then((result) => {
          setPeriods(result.data);
          setOriginalPeriods(result.data);
          setAllPeriodos(result.data);
          setIsLoading(false);
        })
        .catch((e) => {
          console.log(e.response.data.error);
          setErrorMessage(e.response.data.error);
          setFetchError(true);
          setPeriods([]);
          setOriginalPeriods([]);
          setAllPeriodos([]);
          setIsLoading(false);
        })
      : getCompanyPeriodsTypes(token, empresaId)
        .then((result) => {
          setPeriods(result.data);
          setOriginalPeriods(result.data);
          setIsLoading(false);
        })
        .catch((e) => {
          console.log(e.response.data.error);
          setErrorMessage(e.response.data.error);
          setFetchError(true);
          setPeriods([]);
          setOriginalPeriods([]);
          setIsLoading(false);
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changeLink]);

  useEffect(() => {
    getPeriod();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box
      sx={{
        height: "100%",
        "& .theme--header": {
          backgroundColor: "#EEF1FC",
        },
      }}
    >
      <Grid container spacing={2} sx={{ py: "24px" }}>
        <Grid display="flex" sx={{ placeItems: "center" }} item xs={6}>
          <Box sx={{ marginRight: 4, width: "459px" }}>
            <SearchBar
              value={searched}
              onChange={(searchVal: string) => requestSearch(searchVal)}
              onCancelSearch={() => cancelSearch()}
              placeholder="Buscar tipos de periodos"
              style={{ boxShadow: "none" }}
            />
          </Box>
        </Grid>
        {changeLink === 1 && (
          <Grid item xs={6}>
            <Grid container display="flex" justifyContent="flex-end">
              <Stack direction="row" spacing={2}>
                {/* <Button
                variant="contained"
                startIcon={<MaterialIcons.FilterAltIcon />}
                sx={{
                  backgroundColor: "white",
                  color: "black",
                  "&:hover": { color: "white" },
                }}
              >
                Filtrar
              </Button> */}
                {canCreatePeriodType ?
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => addPeriod()}
                  >
                    Crear tipo de periodo
                  </Button>

                  : (
                    <HtmlTooltip
                      title={<b>No tienes permisos para crear novedades</b>}
                    >
                      <span>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => addPeriod()}
                          disabled
                        >
                          Crear tipo de periodo
                        </Button>
                      </span>
                    </HtmlTooltip>)
                }


              </Stack>
            </Grid>
          </Grid>
        )}
      </Grid>
      <DataTable
        columns={columns}
        rows={periods}
        loading={isLoading}
        noFilterRowsMessage="No existen tipos de periodos con ese nombre"
        noRowsMessage={fetchError ? errorMessage : "No hay tipos de periodos"}
        noRowClick
        onCellClick={handleOnCellClick}
      />
    </Box>
  );
};

export default TiposPeriodos;
