import React, { SetStateAction, useContext, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { shallow } from "zustand/shallow";
// import { useHasRoles } from "react-simple-rbac";

import Box from "@mui/material/Box";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import SearchBar from "material-ui-search-bar";

import { MaterialIcons } from "../../../icons/Material/MaterialIcons";
import DataTable from "../../table/DataTable";

import InformationModal from "../../Modals/ConfirmationModal/InformationModal";

import { DrawerContext } from "../../../context/drawer/DrawerContext";

import { deleteUserFromCompany } from "../../../services/CompaniesService";

import { useEmpresaStore } from "../../../store/empresaStore";

import { CompanyUsersRowsType } from "../../../types";
import { useUserStore } from "../../../store/userStore";
import HtmlTooltip from "../../HtmlTooltip/HtmlTooltip";
import { useHasPermissions } from "react-simple-rbac";

const usersRoles = [
  {
    id: 1,
    value: "1",
    label: "Básico",
  },
  {
    id: 2,
    value: "2",
    label: "Cliente Administrador",
  },
  {
    id: 3,
    value: "3",
    label: "BDO Administrador",
  },
  {
    id: 4,
    value: "4",
    label: "Cliente Básico",
  },
  {
    id: 5,
    value: "5",
    label: "BDO Básico",
  },
  {
    id: 6,
    value: "6",
    label: "BDO Gerente",
  },
  {
    id: 7,
    value: "7",
    label: "BDO Lectura",
  },
];

//0 inactive, 1 active, 2 pending
const getStatus = (params: GridRenderCellParams) => {
  if (params.value === 0) {
    return <Typography marginLeft="8px">Inactivo</Typography>;
  }

  if (params.value === 1) {
    return <Typography marginLeft="8px">Activo</Typography>;
  }

  if (params.value === 2) {
    return <Typography marginLeft="8px">Activo</Typography>;
  }
};

//0 basic, 1 administrator, ...
const getRole = (params: GridRenderCellParams) => {
  const userRole = usersRoles.find((uR) => uR.id === params.value);
  if (userRole) {
    return userRole.label;
  } else {
    return "No asignado";
  }
};

//0 cliente, 1 bdo, ...
const getUserType = (params: GridRenderCellParams) => {
  if (params.row.user.type === 0) {
    return "Cliente";
  }

  if (params.row.user.type === 1) {
    return "BDO";
  }
};

type CommonUsersTableType = {
  inviteUser: () => void;
  refreshTable: boolean;
  setRefreshTable: (status: boolean) => void;
  setErrorMessage?: React.Dispatch<SetStateAction<string>>;
  users: CompanyUsersRowsType[];
  originalUsers: CompanyUsersRowsType[];
  setUsers: React.Dispatch<SetStateAction<CompanyUsersRowsType[]>>;
  isLoading: boolean;
  fetchUsersError?: boolean;
  fetchUsersErrorMessage?: string;
};

const CommonUsersTable: React.FC<CommonUsersTableType> = ({
  inviteUser,
  refreshTable,
  setRefreshTable,
  setErrorMessage,
  users,
  originalUsers,
  setUsers,
  isLoading,
  fetchUsersError,
  fetchUsersErrorMessage,
}) => {
  const [searched, setSearched] = useState<string>("");
  const [openEliminarModal, setOpenEliminarModal] = useState<boolean>(false);
  const [filter, setFilter] = useState<any[]>([]);
  const [filterStatus, setFilterStatus] = useState<number>(0);
  const [userData, setUserData] = useState<any>({
    user_id: "",
    company_id: "",
    role: "",
    status: "",
  });

  const { getAccessTokenSilently } = useAuth0();

  const empresaId = useEmpresaStore((state) => state.empresaId, shallow);

  const userDataStore = useUserStore((state) => state.user?.role, shallow);

  const userCanAddUser = userDataStore !== 4 && userDataStore !== 6;
  const canUserAddUser = useHasPermissions(["create:invite-user"]);

  const { changeLink } = useContext(DrawerContext);

  // const userIsManager = useHasRoles(["manager"]);

  const handleOpenModal = () => {
    setOpenEliminarModal(true);
  };

  const handleCloseModal = () => {
    setOpenEliminarModal(false);
  };

  const requestSearch = (searchedVal: string) => {
    const seacrhUsers = [...originalUsers];
    if (searchedVal.length > 2) {
      const filteredRowsByName = seacrhUsers.filter((user) => {
        return user.user.given_name
          .toLowerCase()
          .includes(searchedVal.toLowerCase());
      });

      const filteredRowsByEmail = seacrhUsers.filter((user) => {
        return user.user.email
          .toLowerCase()
          .includes(searchedVal.toLowerCase());
      });

      if (filteredRowsByName.length > 0) {
        setUsers(filteredRowsByName);
      } else if (filteredRowsByEmail.length > 0) {
        setUsers(filteredRowsByEmail);
      } else {
        setUsers([]);
      }
    } else {
      setUsers(originalUsers);
    }
  };

  const handleSetFilter = () => {
    switch (filterStatus) {
      case 0:
        setFilterStatus(1);
        setFilter([
          {
            columnField: "status",
            operatorValue: "equals",
            value: "0",
          },
        ]);
        break;
      case 1:
        setFilterStatus(2);
        setFilter([
          {
            columnField: "status",
            operatorValue: "equals",
            value: "1",
          },
        ]);

        break;
      case 2:
        setFilterStatus(3);
        setFilter([
          {
            columnField: "status",
            operatorValue: "equals",
            value: "2",
          },
        ]);

        break;

      default:
        setFilter([]);
        setFilterStatus(0);
        break;
    }
  };

  const cancelSearch = () => {
    setSearched("");
    requestSearch(searched);
  };

  const handleClickEliminar = (user: any) => {
    const userData = {
      user_id: user.id,
      company_id: empresaId,
      role: user.role,
      status: user.status,
      email: user.user.email,
    };

    setUserData(userData);
    handleOpenModal();
  };

  const removeUserFromCompany = async () => {
    const token = await getAccessTokenSilently();

    await deleteUserFromCompany(token, userData.user_id, userData)
      .then(() => {
        setRefreshTable(true);
        handleCloseModal();
      })
      .catch((e) => {
        if (setErrorMessage) {
          setErrorMessage(e.response.data.error);
        }
      });
  };

  //1: success, 2:pending, 3:cancel
  const getAction = (params: GridRenderCellParams, changeLink: number) => {
    if (changeLink === 2) {
      return;
    }

    return (
      <Box
        sx={{
          display: "flex",
          placeItems: "center",
          justifyContent: "flex-end",
          width: "100%",
        }}
      >
        <IconButton
          color="inherit"
          onClick={() => handleClickEliminar(params.row)}
        // disabled={params.row.user_id === user.id}
        >
          <MaterialIcons.DeleteIcon />
        </IconButton>
      </Box>
    );
  };

  const columns: GridColDef[] = [
    {
      field: "given_name",
      headerName: "Nombre",
      flex: 1,
      editable: true,
      headerClassName: "theme--header",
      valueGetter(params) {
        return params.row.user.given_name;
      },
    },
    {
      field: "family_name",
      headerName: "Apellido",
      flex: 1,
      editable: true,
      headerClassName: "theme--header",
      valueGetter(params) {
        return params.row.user.family_name;
      },
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
      editable: true,
      headerClassName: "theme--header",
      valueGetter(params) {
        return params.row.user.email;
      },
    },
    {
      field: "role",
      headerName: "Rol",
      flex: 1,
      renderCell: getRole,
      headerClassName: "theme--header",
    },
    {
      field: "type",
      headerName: "Tipo",
      flex: 1,
      renderCell: getUserType,
      headerClassName: "theme--header",
    },
    {
      field: "status",
      headerName: "Estado",
      headerClassName: "theme--header",
      flex: 1,
      renderCell: getStatus,
    },
    {
      field: "action",
      headerName: "Acción",
      type: "number",
      flex: 1,
      editable: false,
      headerClassName: "theme--header",
      renderCell(params) {
        return getAction(params, changeLink);
      },
    },
  ];

  return (
    <Box
      sx={{
        height: "76%",
        "& .theme--header": {
          backgroundColor: "#EEF1FC",
        },
      }}
    >
      <Grid container spacing={2} sx={{ py: "24px" }}>
        <Grid display="flex" sx={{ placeItems: "center" }} item xs={6}>
          <Box sx={{ marginRight: 4, width: "459px" }}>
            <SearchBar
              value={searched}
              onChange={(searchVal: string) => requestSearch(searchVal)}
              onCancelSearch={() => cancelSearch()}
              placeholder="Buscar usuario"
              style={{ boxShadow: "none" }}
            />
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Grid container display="flex" justifyContent="flex-end">
            <Stack direction="row" spacing={2}>
              <Button
                variant="contained"
                startIcon={<MaterialIcons.FilterAltIcon />}
                onClick={() => handleSetFilter()}
                sx={{
                  backgroundColor: "white",
                  color: "black",
                  "&:hover": { color: "white" },
                }}
              >
                {filterStatus
                  ? `Estado: ${filterStatus === 1
                    ? "Inactivo"
                    : filterStatus === 2
                      ? "Activo"
                      : "Pendiente"
                  }`
                  : "Filtrar"}
              </Button>
              {/* {(changeLink === 1 || userIsManager) && ( */}

              {canUserAddUser ? (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => inviteUser()}
                >
                  Invitar usuario
                </Button>
              ) : (
                <HtmlTooltip
                  title={<b>No tienes permisos para invitar usuarios</b>}
                >
                  <span>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => inviteUser()}
                      disabled
                    >
                      Invitar usuario
                    </Button>
                  </span>
                </HtmlTooltip>
              )}
              {/*<Button
                variant="contained"
                color="primary"
                onClick={() => inviteUser()}
              >
                {changeLink === 1 ? "Invitar usuariojeje" : "Crear usuariojeje"}
              </Button>*/}
            </Stack>
          </Grid>
        </Grid>
      </Grid>
      <DataTable
        columns={columns}
        rows={users}
        noFilterRowsMessage="No hay usuarios con ese nombre"
        noRowsMessage={
          fetchUsersError ? fetchUsersErrorMessage : "No hay usuarios"
        }
        loading={isLoading}
        filter={filter}
        noRowClick
        sortModel={[{ field: "given_name", sort: "asc" }]}
      />

      {openEliminarModal && (
        <InformationModal
          title="Eliminar usuario"
          message={`¿Querés eliminar este usuario ${userData.email}?`}
          openModal={openEliminarModal}
          onClose={handleCloseModal}
          isDirty={false}
          clickCancelar={false}
          guardar={false}
          guardarMessage={`¿Querés eliminar este usuario ${userData.email}?`}
          clickEliminar={true}
          onEliminar={() => removeUserFromCompany()}
        />
      )}
    </Box>
  );
};

export default CommonUsersTable;
