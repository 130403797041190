import React, { useCallback, useEffect, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { shallow } from "zustand/shallow";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import CustomBreadcrumbs from "../CustomBreadcrumbs/CustomBreadcrumbs";
import HtmlTooltip from "../HtmlTooltip/HtmlTooltip";
import InformationModal from "../Modals/ConfirmationModal/InformationModal";
import Layout from "../Layout/Layout";

import { MaterialIcons } from "../../icons/Material/MaterialIcons";

import {
  addCompanyNovelty,
  getCompanyNoveltiesTypes,
} from "../../services/CompaniesService";

import {
  downloadNoveltyFile,
  getCloserPeriods,
} from "../../services/ParametersService";

import { useEmpresaStore } from "../../store/empresaStore";
import { useParametrosStore } from "../../store/parametrosStore";

import { reorderPeriods } from "../../utils/reorderPeriods";

import { CompanyPeriodsType } from "../../types";

const TooltipMsg = () => {
  return (
    <>
      <Typography color="inherit">
        <b>{"Prioridad 0"}</b>: Novedades que no tienen "prioridad" o no
        dependen unas de otras.
      </Typography>
      <Typography color="inherit">
        <b>{"Prioridad 1+n"}</b>: Para todos los casos que existan dependencias
        para procesarse.
      </Typography>
    </>
  );
};

const links: any[] = [
  { id: "1", value: "Novedades", isLink: true, to: "/novedades" },
  { id: "2", value: "Crear novedad", isLink: false },
];

const CrearNovedad = () => {
  const [openInformationModal, setOpenInformationModal] =
    useState<boolean>(false);
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const [clickCancelar, setClickCancelar] = useState<boolean>(false);
  const [clickGuardar, setClickGuardar] = useState<boolean>(false);
  const [openErrorModal, setOpenErrorModal] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [downloadFile, setDownlaodFile] = useState<any>(null);
  const [empresaTipoNovedades, setEmpresaTipoNovedades] = useState<any[]>([]);
  const [empresaPeriodos, setEmpresaPeriodos] = useState<CompanyPeriodsType[]>(
    []
  );
  const [actualEmpresaNovedades, setActualEmpresaNovedades] = useState<any[]>(
    []
  );
  const [disabledOnSubmit, setDisabledOnSubmit] = useState<boolean>(false);

  const navigate = useNavigate();

  const { getAccessTokenSilently } = useAuth0();

  // const { actualEmpresaNovedades } = useContext(ParametroContext);

  const setAllCompanyNovedadesType = useParametrosStore(
    (state) => state.setAllCompanyNovedadesType
  );

  const { allEmpresaPeriodos, empresaId } = useEmpresaStore(
    ({ allEmpresaPeriodos, empresaId }) => ({ allEmpresaPeriodos, empresaId }),
    shallow
  );

  const handleOpenModal = () => {
    setOpenInformationModal(true);
  };

  const handleCloseModal = () => {
    setOpenInformationModal(false);
    setOpenErrorModal(false);
    setClickCancelar(false);
    setClickGuardar(false);
    setErrorMessage("");
  };

  const handleClickDirtyCancelar = () => {
    setIsDirty(true);
    setClickGuardar(false);
    setClickCancelar(true);
    handleOpenModal();
  };

  const handleClickGuardar = () => {
    setIsDirty(false);
    setClickCancelar(false);
    setClickGuardar(true);
    handleOpenModal();
  };

  const handleDeshacerCancelar = () => {
    formik.resetForm();
    navigate(-1);
  };

  const handleSaveButton = () => {
    formik.submitForm();
  };

  const getCloserPeriod = (unOrderedPeriods: any[]) => {
    if (unOrderedPeriods.length > 0) {
      const periodos = [...unOrderedPeriods];
      const sortedPeriods = [...reorderPeriods(periodos)];
      formik.setFieldValue("period_id", sortedPeriods[0].id);
      setEmpresaPeriodos(sortedPeriods);
    }
  };

  const downloadArch = async () => {
    const token = await getAccessTokenSilently();
    const result = await downloadNoveltyFile(token, downloadFile.ref);

    // Create blob link to download
    const link = document.createElement("a");
    const url = window.URL.createObjectURL(new Blob([result.data]));
    link.href = url;
    link.setAttribute("download", downloadFile.name);

    // Append to html link element page
    document.body.appendChild(link);

    // Start download
    link.dispatchEvent(new MouseEvent("click"));

    // Clean up and remove the link
    link.parentNode?.removeChild(link);
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      period_id: "",
      novelty_type_id: "",
      fileName: "",
      file: "",
      urgent: false,
      priority: 0,
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .max(55)
        .required("El nombre de la novedad es requerido"),
      priority: Yup.number()
        .min(0, "La prioridad debe ser mayor o igual a 0")
        .required("La prioridad de la novedad es requerida"),
      period_id: Yup.string()
        .max(255)
        .required("Seleccione un tipo de periodo"),
      fileName: Yup.mixed().required("Debe subir un (1) archivo"),
      novelty_type_id: Yup.string()
        .max(255)
        .required("Seleccione un tipo de novedad"),
    }),
    onSubmit: async (data) => {
      setDisabledOnSubmit(true);
      const finalData = {
        file: data.file,
        name: data.name,
        period_id: data.period_id,
        company_id: empresaId,
        novelty_type_id: findNoveltyTypeId(data.novelty_type_id),
        urgent: data.urgent,
        priority: data.priority,
      };

      const token = await getAccessTokenSilently();
      addCompanyNovelty(token, empresaId, finalData)
        .then(() => {
          setOpenErrorModal(false);
          setErrorMessage("");
          setTimeout(() => {
            handleCloseModal();
            handleDeshacerCancelar();
          }, 4000);
        })
        .catch((e) => {
          console.error(e.response.data.error);
          setDisabledOnSubmit(false);
          setOpenErrorModal(true);
          setErrorMessage(e.response.data.error);
        });
    },
  });

  const handleUploadFile = (file: any) => {
    formik.setFieldValue("file", file);
    formik.setFieldValue("fileName", file.name);
  };

  const handleChangePriority = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (Number(e.target.value) >= 0) {
      formik.setFieldValue("priority", Number(e.target.value));
    }
  };

  const findNoveltyTypeId = (noveltyTypeId: string): string => {
    return empresaTipoNovedades.length > 0
      ? empresaTipoNovedades.find((eTN) => eTN.id === noveltyTypeId).id
      : "";
  };

  const handleChangeNoveltyType = (e: React.ChangeEvent<HTMLInputElement>) => {
    const downloadFileRef = actualEmpresaNovedades.find(
      (aEN) => aEN.id === e.target.value
    ).file;

    formik.setFieldValue("novelty_type_id", findNoveltyTypeId(e.target.value));

    setDownlaodFile(downloadFileRef);
  };

  const getActualEmpresaNovedadesType = useCallback(async () => {
    const token = await getAccessTokenSilently();
    getCompanyNoveltiesTypes(token, empresaId)
      .then((result) => {
        const sortedNovelties = result.data.sort(function (
          a: { novelty_type: { name: string } },
          b: { novelty_type: { name: string } }
        ) {
          if (a.novelty_type.name < b.novelty_type.name) {
            return -1;
          }
          if (a.novelty_type.name > b.novelty_type.name) {
            return 1;
          }
          return 0;
        });
        setActualEmpresaNovedades(sortedNovelties);
        setAllCompanyNovedadesType(sortedNovelties);
      })
      .catch((e) => {
        console.log("error", e.response.data.error);
        setActualEmpresaNovedades([]);
        setAllCompanyNovedadesType([]);
      });

    // const result = await getCompanyNovelties(token, empresaId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const obtenerCloserPeriods = useCallback(async () => {
    const token = await getAccessTokenSilently();
    getCloserPeriods(token, empresaId)
      .then((result) => {
        console.table(result.data);
        getCloserPeriod(result.data);
      })
      .catch((e) => {
        console.log("error", e.response.data.error);
      });

    // const result = await getCompanyNovelties(token, empresaId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    document.title = `${window._env_.REACT_APP_EMPRESA_NAME} - Crear novedad`;
  }, []);

  useEffect(() => {
    if (actualEmpresaNovedades.length > 0) {
      formik.setFieldValue("novelty_type_id", actualEmpresaNovedades[0].id);
      setDownlaodFile(actualEmpresaNovedades[0].file);
      setEmpresaTipoNovedades(actualEmpresaNovedades);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actualEmpresaNovedades]);

  useEffect(() => {
    if (allEmpresaPeriodos.length > 0) {
      setEmpresaPeriodos(allEmpresaPeriodos);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allEmpresaPeriodos]);

  useEffect(() => {
    obtenerCloserPeriods();
    getActualEmpresaNovedadesType();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout title="Crear novedad" noIcon>
      <CustomBreadcrumbs icon="PollIcon" links={links} />
      <Grid container marginTop="12px" borderRadius="6px">
        <Grid bgcolor="white" item xs={8} borderRadius="6px">
          <Box
            sx={{
              borderRadius: "6px",
              display: "flex",
              backgroundColor: "white",
              height: "100%",
              width: "100%",
              paddingRight: "18px",
            }}
          >
            <Box sx={{ flexGrow: 1 }}>
              <Box
                id="create-novelty-form"
                component="form"
                autoComplete="off"
                borderRadius="6px"
                sx={{ backgroundColor: "white", height: "100%" }}
                onSubmit={formik.handleSubmit}
              >
                <Grid container>
                  <Grid item xs={6} sx={{ pt: "18px", pl: "18px" }}>
                    <Box
                      display="flex"
                      justifyContent="flex-start"
                      marginBottom="4px"
                    >
                      <InputLabel
                        sx={{ color: "black" }}
                        htmlFor="nombre-input"
                      >
                        Nombre
                      </InputLabel>
                    </Box>
                    <TextField
                      id="nombre-input"
                      error={Boolean(formik.touched.name && formik.errors.name)}
                      fullWidth
                      helperText={formik.touched.name && formik.errors.name}
                      name="name"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      type="text"
                      value={formik.values.name}
                      variant="filled"
                      placeholder="Nombre de la novedad"
                      InputProps={{
                        disableUnderline: true,
                        sx: {
                          borderRadius: "6px",
                          ".MuiInputBase-input": {
                            paddingBottom: "16px",
                            paddingTop: "16px",
                          },
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Grid container alignItems="center">
                      <Grid item xs={6} sx={{ pt: "18px", pl: "18px" }}>
                        <Box
                          display="flex"
                          justifyContent="flex-start"
                          marginBottom="4px"
                        >
                          <InputLabel
                            sx={{ color: "black" }}
                            htmlFor="type-select"
                          >
                            Tipo
                          </InputLabel>
                        </Box>
                        <TextField
                          id="type-select"
                          select
                          error={Boolean(
                            formik.touched.novelty_type_id &&
                              formik.errors.novelty_type_id
                          )}
                          fullWidth
                          helperText={
                            formik.touched.novelty_type_id &&
                            formik.errors.novelty_type_id
                          }
                          name="novelty_type_id"
                          onBlur={formik.handleBlur}
                          onChange={handleChangeNoveltyType}
                          value={formik.values.novelty_type_id}
                          variant="filled"
                          InputProps={{
                            disableUnderline: true,
                            sx: {
                              borderRadius: "6px",
                              ".MuiInputBase-input": {
                                paddingBottom: "16px",
                                paddingTop: "16px",
                                display: "flex",
                                justifyContent: "flex-start",
                              },
                            },
                          }}
                        >
                          {empresaTipoNovedades.map((empresaTipoNovedad) => (
                            <MenuItem
                              key={empresaTipoNovedad.id}
                              value={empresaTipoNovedad.id}
                            >
                              {`${empresaTipoNovedad.novelty_type.code} - ${empresaTipoNovedad.novelty_type.name}`}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                      <Grid item xs={6} sx={{ pt: "48px", pl: "18px" }}>
                        <Box
                          display="flex"
                          justifyContent="flex-start"
                          marginBottom="4px"
                        >
                          <Button
                            color="primary"
                            variant="contained"
                            startIcon={<MaterialIcons.FileDownloadIcon />}
                            onClick={() => downloadArch()}
                            sx={{ height: "100%" }}
                          >
                            Descargar
                          </Button>
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={6} sx={{ pt: "18px", pl: "18px" }}>
                    <Box
                      display="flex"
                      justifyContent="flex-start"
                      marginBottom="4px"
                    >
                      <InputLabel
                        sx={{ color: "black" }}
                        htmlFor="periodo-select"
                      >
                        Periodo
                      </InputLabel>
                    </Box>
                    <TextField
                      id="periodo-select"
                      select
                      error={Boolean(
                        formik.touched.period_id && formik.errors.period_id
                      )}
                      fullWidth
                      helperText={
                        formik.touched.period_id && formik.errors.period_id
                      }
                      name="period_id"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.period_id}
                      variant="filled"
                      InputProps={{
                        disableUnderline: true,
                        sx: {
                          borderRadius: "6px",
                          ".MuiInputBase-input": {
                            paddingBottom: "16px",
                            paddingTop: "16px",
                            display: "flex",
                            justifyContent: "flex-start",
                          },
                        },
                      }}
                    >
                      {empresaPeriodos.map((periodo) => (
                        <MenuItem key={periodo.id} value={periodo.id}>
                          {periodo.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={6} sx={{ pt: "18px", pl: "18px" }}>
                    <Box
                      display="flex"
                      justifyContent="flex-start"
                      marginBottom="4px"
                    >
                      <InputLabel
                        sx={{ color: "black" }}
                        htmlFor="prioridad-input"
                      >
                        Prioridad
                      </InputLabel>
                    </Box>
                    <HtmlTooltip title={<TooltipMsg />} arrow>
                      <TextField
                        id="prioridad-input"
                        error={Boolean(
                          formik.touched.priority && formik.errors.priority
                        )}
                        fullWidth
                        helperText={
                          formik.touched.priority && formik.errors.priority
                        }
                        name="priority"
                        onBlur={formik.handleBlur}
                        onChange={handleChangePriority}
                        type="number"
                        value={formik.values.priority}
                        variant="filled"
                        placeholder="Prioridad"
                        inputProps={{
                          min: 0,
                        }}
                        InputProps={{
                          disableUnderline: true,
                          sx: {
                            borderRadius: "6px",
                            ".MuiInputBase-input": {
                              paddingBottom: "16px",
                              paddingTop: "16px",
                            },
                          },
                        }}
                      />
                    </HtmlTooltip>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={6} sx={{ pt: "18px", pl: "18px" }}>
                    <Box
                      display="flex"
                      justifyContent="flex-start"
                      marginBottom="4px"
                    >
                      <InputLabel
                        sx={{ color: "black" }}
                        htmlFor="novedad-input"
                      >
                        Novedad
                      </InputLabel>
                    </Box>
                    <TextField
                      id="novedad-input"
                      error={Boolean(
                        formik.touched.fileName && formik.errors.fileName
                      )}
                      fullWidth
                      helperText={
                        formik.touched.fileName && formik.errors.fileName
                      }
                      name="fileName"
                      value={formik.values.fileName}
                      variant="filled"
                      placeholder="Subir archivo"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <HtmlTooltip title={<b>Subir archivo</b>}>
                              <IconButton
                                aria-label="subir plantilla button"
                                component="label"
                              >
                                <input
                                  hidden
                                  id="subir-plantilla-button"
                                  type="file"
                                  name="file"
                                  onChange={(e: any) =>
                                    handleUploadFile(e.currentTarget.files[0])
                                  }
                                  accept=".csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                />
                                <MaterialIcons.FileUploadIcon color="primary" />
                              </IconButton>
                            </HtmlTooltip>
                          </InputAdornment>
                        ),
                        readOnly: true,
                        disableUnderline: true,
                        sx: {
                          borderRadius: "6px",
                          ".MuiInputBase-input": {
                            paddingBottom: "16px",
                            paddingTop: "16px",
                          },
                        },
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={2} sx={{ pb: "24px", pt: "8px", pl: "18px" }}>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="urgent"
                            checked={formik.values.urgent}
                            onChange={formik.handleChange}
                            inputProps={{
                              "aria-label": "urgente-checkbox",
                            }}
                          />
                        }
                        label="Urgente"
                      />
                    </FormGroup>
                  </Grid>
                </Grid>
                <Grid container maxWidth="sm" paddingBottom="32px">
                  <Box sx={{ display: "flex" }}>
                    <Button
                      sx={{ marginLeft: "18px" }}
                      variant="contained"
                      color="primary"
                      type="button"
                      onClick={handleClickGuardar}
                      disabled={
                        !formik.dirty ||
                        !formik.values.period_id ||
                        !formik.values.name ||
                        !formik.values.fileName ||
                        !formik.values.novelty_type_id
                      }
                    >
                      Guardar
                    </Button>
                      
                    {formik.values.name || formik.values.fileName ? (
                      <Button
                        sx={{ marginLeft: "18px" }}
                        variant="contained"
                        color="error"
                        onClick={handleClickDirtyCancelar}
                      >
                        Cancelar
                      </Button>
                    ) : (
                      <Button
                        sx={{ marginLeft: "18px" }}
                        variant="contained"
                        color="error"
                        onClick={() => navigate(-1)}
                      >
                        Cancelar
                      </Button>
                    )}
                  </Box>
                </Grid>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>

      {openInformationModal && (
        <InformationModal
          title={isDirty ? "Cancelar cambios" : "Crear novedad"}
          message={
            isDirty
              ? "¿Querés cancelar los cambios realizados?"
              : "¿Querés crear ésta novedad?"
          }
          openModal={openInformationModal}
          onClose={handleCloseModal}
          saveForm={handleSaveButton}
          isDirty={isDirty}
          clickCancelar={clickCancelar}
          handleDeshacerCancelar={handleDeshacerCancelar}
          guardar={clickGuardar}
          guardarMessage="¿Querés guardar ésta novedad?"
          clickEliminar={false}
          disabledOnSubmit={disabledOnSubmit}
        />
      )}

      {openErrorModal && (
        <InformationModal
          title="Ha ocurrido un error"
          message={errorMessage}
          onClose={handleCloseModal}
          openModal={openErrorModal}
          clickCancelar={false}
          clickEliminar={false}
          guardar={false}
          isDirty={false}
          errorMessage
        />
      )}
    </Layout>
  );
};

export default CrearNovedad;
